import React from 'react';
import Layout from '../layouts/index';
import BookImage from '../images/course-listeningPractice.png';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { ReactPhotoCollage } from 'react-photo-collage';
import Recommended from '../components/Recommended';
import Payhip from '../components/Payhip';

class CourseDetails extends React.Component {
	render() {
		const setting = {
			width: '100%',
			height: ['500px', '170px'],
			layout: [1, 4],
			photos: [
				{
					src: 'https://raw.githubusercontent.com/elingos/ElingosAssets/master/Assets/patterns-book1-preview/book1-page1.png',
				},
				{
					src: 'https://raw.githubusercontent.com/elingos/ElingosAssets/master/Assets/patterns-book1-preview/book1-page2.png',
				},
				{
					src: 'https://raw.githubusercontent.com/elingos/ElingosAssets/master/Assets/patterns-book1-preview/book1-page3.png',
				},
				{
					src: 'https://raw.githubusercontent.com/elingos/ElingosAssets/master/Assets/patterns-book1-preview/book1-page4.png',
				},
				{
					src: 'https://raw.githubusercontent.com/elingos/ElingosAssets/master/Assets/patterns-book1-preview/book1-page5.png',
				},
			],
			showNumOfRemainingPhotos: true,
		};
		return (
			<Layout>
				{/* Course info */}
				<div className="container-fluid margin-from-header bg-white">
					<div className="container pt-4 pt-md-8 pb-8">
						<div className="row d-flex justify-content-md-center reverse-flex-mobile">
							<div className="col-sm-12 col-md-5 d-flex flex-column justify-content-left mr-md-6">
								<h2>Story Listening Practice in Vietnamese</h2>
								<p>
									Practice your listening with 6 short stories in Vietnamese on
									various topics, read to you at both slow & normal speed.
									Suitable for upper-beginner to intermediate level.
								</p>
								<p>
									Level: <b>Upper Beginner - Intermediate</b>
								</p>

								{/*<h1 className="text-success">$9.99</h1>*/}
								<div className="row pl-1 mt-2">
									<Payhip productID="7nAzG" label="Buy now - $12.99"></Payhip>
									{/* <OutboundLink
                    class="btn-simple btn-primary-outline"
                    // href="https://gum.co/vjdom?wanted=true"
                    href="https://payhip.com/b/7nAzG"
                  >
                    Get now for $12
                  </OutboundLink> */}
								</div>
							</div>
							<div className="col-7 col-md-3 mb-2 align-self-center">
								<img
									src={BookImage}
									className="img-fluid float-right rounded"
									alt="Story Listening Practice in Vietnamese"
								></img>
							</div>
						</div>
					</div>
				</div>

				{/* Course contents */}
				<div className="container pt-md-10 pb-md-10 pt-6 pb-6">
					<div className="row justify-content-md-center">
						<div className="col-sm-12 col-md-8 ml-1 mr-1">
							<h2 className="">What's included?</h2>
							<p>
								<b>PDF Lesson Note - 6 files, includes:</b>
							</p>
							<ul>
								<li>Story Transcript in Vietnamese</li>
								<li>English Translation</li>
								<li>Key vocabulary list</li>
							</ul>
							<p>
								<b>Audio MP3 - 12 files, includes:</b>
							</p>
							<ul>
								<li>Slow speed version</li>
								<li>Normal speed version</li>
							</ul>
							<h2 className="pt-6">Best way to study with this material?</h2>
							<p>
								<b>Step 1: Understand the story</b>
							</p>
							<ul>
								<li>1A - Read the transcript.</li>
								<li>
									1B - Check for key vocabulary words that can help you
									understand the overall meaning of the story.
								</li>
							</ul>

							<p>
								<b>Step 2: Understand the sentences</b>
							</p>
							<ul>
								<li>
									2A - Translate each sentence into English. Focus on the
									sentence structures.{' '}
								</li>
								<li>
									2B - Check the given translation and notes for difficult
									grammar points.
								</li>
							</ul>

							<p>
								<b>Step 3: Active listening</b>
							</p>
							<ul>
								<li>
									3A - Play the audio and follow along with the transcript.
								</li>
								<li>3B - Focus on pronunciation and rhythm.</li>
							</ul>

							<p>
								<b>Step 4: Passive listening</b>
							</p>
							<ul>
								<li>
									4A - Listen to the audio passively without the transcript.
								</li>
								<li>
									4B - Replay the audio as many times as you need until you can
									understand the story without translating.
								</li>
							</ul>
						</div>
					</div>
				</div>
				<Recommended
					recommended={[
						'/vietnamese-ebook-bundle',
						'/vietnamese-grammar-book-bundle',
					]}
				/>
			</Layout>
		);
	}
}

export default CourseDetails;
